<template>
    <ContentContainer title="About">
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <div class="mb-5">
                        <h2>Cisco FCOM</h2>
                    </div>
                    <div class="body-2">
                        Ziel der Anwendung ist es im Bereich Opportunity attraktive Angebote aufzunehmen und im besten Fall
                        eine Bestätigung seitens der Cisco zu erreichen. <br>
                        Cisco FCOM unterstützt diesen Prozess durch die Pflege von Opportunities und der damit
                        einhergehenden Steuerung der Opportunities.
                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col cols="12">
                    <div class="mb-5">
                        <h2>Ansprechpartner</h2>
                    </div>
                    <v-container fluid class="pa-0">
                        <v-row>
                            <v-col v-for="contact in contacts" :cols="'6'" :key="contact.name">
                                <v-card outlined elevation="1">
                                    <v-list-item-content>
                                        <h2 class="mb-1 pl-4" v-text="contact.responsibilities"></h2>
                                    </v-list-item-content>
                                    <v-list-item three-line>
                                        <v-list-item-avatar size="60" color="primary">
                                            <span class="white--text " v-text="contact.initials"></span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class=" mb-1" v-text="contact.name"></v-list-item-title>
                                            <v-list-item-subtitle v-text="contact.jobtitle"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-btn outlined color="indigo" :href="contact.mailto">
                                            <v-icon left>mdi-email</v-icon>Contact
                                        </v-btn>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col cols="12">
                    <div class="mb-5">
                        <h2>Zugriff auf den Cisco FCOM</h2>
                    </div>
                    <span>Um Zugriff auf den Cisco FCOM zu erhalten, schreibe eine Mail an Mirko Russo.</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col cols="12">
                    <div class="mb-5">
                        <h2>Changelog</h2>
                    </div>
                    <v-timeline>
                        <v-timeline-item v-for="(item) in changelog" :key="item.id" small :color="item.color">
                            <template v-slot:opposite>
                                <span :class="`headline font-weight-bold ${item.color}--text`" v-text="item.date"></span>
                            </template>
                            <div class="py-4">
                                <h2 :class="`headline font-weight-light mb-4 ${item.color}--text`" v-text="item.title"></h2>
                                <div v-text="item.description"></div>
                            </div>
                        </v-timeline-item>
                    </v-timeline>
                </v-col>
            </v-row>
        </v-container>
    </ContentContainer>
</template>

<script>
import ContentContainer from "@/components/content/ContentContainer";

export default {
    name: "About",

    metaInfo: { title: "About SpyderX - Cisco FCOM" },

    components: {
        ContentContainer
    },

    props: {
        id: {
            type: String,
            default: "about"
        }
    },

    data: () => ({
        contacts: [
            {
                name: "Benjamin Benedek",
                initials: "BB",
                responsibilities: "Fachliche und Technische Fragen",
                jobtitle: "CUD Consultant",
                mailto:
                    "mailto:Benjamin.Benedek@computacenter.com?subject=Question about Cisco FCOM"
            },
            {
                name: "Ruining Liu",
                initials: "RL",
                responsibilities: "Technische Fragen",
                jobtitle: "CUD IT Associate",
                mailto:
                    "mailto:Ruining.Liu@computacenter.com?subject=Question about Cisco FCOM"
            },
            {
                name: "Mirko Russo",
                initials: "MR",
                responsibilities: "Fachliche Fragen",
                jobtitle: "Business Development Specialist",
                mailto:
                    "mailto:Mirko.Russo@computacenter.com?subject=Question about Cisco FCOM"
            },
            {
                name: "Chau Hung Tran",
                initials: "CT",
                responsibilities: "Technische Fragen",
                jobtitle: "CUD IT Specialist",
                mailto:
                    "mailto:chauhung.tran@computacenter.com?subject=Question about Cisco FCOM"
            },
        ],
        changelog: [
            {
                id: 1,
                date: "01.06.2021",
                title: "First Release",
                description:
                    "Basis-Funktionalität zur Erfassung und Bestätigung von Opportunities.",
                color: "success"
            },
            {
                id: 2,
                date: "30.06.2021",
                title: "Second Release",
                description:
                    "Integration der AG Grid. Die Tabellen Information können als Excel exportiert werden. Opportunities können dupliziert und auf gewonnen/verloren gesetzt werden.",
                color: "success"
            },
            {
                id: 3,
                date: "03.06.2022",
                title: "Third Release",
                description:
                    "Erweiterung und Einführung der Opportunity States. Diese werden in einer Chronik festgehalten.",
                color: "success"
            },
            {
                id: 4,
                date: "05.10.2022",
                title: "Fourth Release",
                description:
                    "Excel Import für die Kunden.",
                color: "success"
            },
            {
                id: 5,
                date: "10.07.2023",
                title: "Fifth Release",
                description:
                    "Erstellung eines Angebotes mit Hilfe von einem Word-Template.",
                color: "success"
            },
        ].reverse() // Show newest entries on Top.
    })
};
</script>

<style lang="scss">
.v-avatar {
    justify-content: center !important
}

.v-list-item--three-line .v-list-item__avatar {
    align-self: center !important
}
</style>
