var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContentContainer",
    { attrs: { title: "About" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "mb-5" }, [
                  _c("h2", [_vm._v("Cisco FCOM")]),
                ]),
                _c("div", { staticClass: "body-2" }, [
                  _vm._v(
                    " Ziel der Anwendung ist es im Bereich Opportunity attraktive Angebote aufzunehmen und im besten Fall eine Bestätigung seitens der Cisco zu erreichen. "
                  ),
                  _c("br"),
                  _vm._v(
                    " Cisco FCOM unterstützt diesen Prozess durch die Pflege von Opportunities und der damit einhergehenden Steuerung der Opportunities. "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "mb-5" }, [
                    _c("h2", [_vm._v("Ansprechpartner")]),
                  ]),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.contacts, function (contact) {
                          return _c(
                            "v-col",
                            { key: contact.name, attrs: { cols: "6" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { outlined: "", elevation: "1" } },
                                [
                                  _c("v-list-item-content", [
                                    _c("h2", {
                                      staticClass: "mb-1 pl-4",
                                      domProps: {
                                        textContent: _vm._s(
                                          contact.responsibilities
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "v-list-item",
                                    { attrs: { "three-line": "" } },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        {
                                          attrs: {
                                            size: "60",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "white--text ",
                                            domProps: {
                                              textContent: _vm._s(
                                                contact.initials
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            staticClass: " mb-1",
                                            domProps: {
                                              textContent: _vm._s(contact.name),
                                            },
                                          }),
                                          _c("v-list-item-subtitle", {
                                            domProps: {
                                              textContent: _vm._s(
                                                contact.jobtitle
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "indigo",
                                            href: contact.mailto,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-email")]
                                          ),
                                          _vm._v("Contact "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "mb-5" }, [
                  _c("h2", [_vm._v("Zugriff auf den Cisco FCOM")]),
                ]),
                _c("span", [
                  _vm._v(
                    "Um Zugriff auf den Cisco FCOM zu erhalten, schreibe eine Mail an Mirko Russo."
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "mb-5" }, [
                    _c("h2", [_vm._v("Changelog")]),
                  ]),
                  _c(
                    "v-timeline",
                    _vm._l(_vm.changelog, function (item) {
                      return _c(
                        "v-timeline-item",
                        {
                          key: item.id,
                          attrs: { small: "", color: item.color },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "opposite",
                                fn: function () {
                                  return [
                                    _c("span", {
                                      class:
                                        "headline font-weight-bold " +
                                        item.color +
                                        "--text",
                                      domProps: {
                                        textContent: _vm._s(item.date),
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("div", { staticClass: "py-4" }, [
                            _c("h2", {
                              class:
                                "headline font-weight-light mb-4 " +
                                item.color +
                                "--text",
                              domProps: { textContent: _vm._s(item.title) },
                            }),
                            _c("div", {
                              domProps: {
                                textContent: _vm._s(item.description),
                              },
                            }),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }